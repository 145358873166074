import React from "react";

import { Container } from "src/components/Container";
import { LinkList, LinkListItem } from "src/components/LinkList";
import { Typography } from "src/components/Typography";
import { PathEnum } from "src/enums/PathEnum";
import { createLink } from "src/utils/createLink";

import * as styles from "./VacancyItem.module.scss";

// todo: Переместить в типы после подключения
export interface ShortVacancy {
  id: string;
  name: string;
  vacancy_categories: LinkListItem[];
}

export const VacancyItem: React.FC<ShortVacancy> = ({
  id,
  name,
  vacancy_categories,
}) => {
  const handleClick = () => {
    window.location.href = createLink(PathEnum.Vacancy, { id });
  };

  return (
    <Container
      rootClassName={styles.root}
      className={styles.content}
      onClick={handleClick}
    >
      <Typography variant="h5">{name}</Typography>
      <LinkList
        items={vacancy_categories}
        linkProps={{
          color: "black",
          hoverFill: "black",
          size: "s",
          className: styles.tag,
        }}
        flexWrap
      />
    </Container>
  );
};
