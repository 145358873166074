import React from "react";

import { PageProps } from "gatsby";
import { ParamsProvider } from "src/components/ParamsProvider";
import { Vacancies } from "src/layouts/Vacancies";

const VacanciesPage = ({location}: PageProps) => {
  return (
    <ParamsProvider location={location}>
      <Vacancies />
    </ParamsProvider>
  );
};

export default VacanciesPage;
