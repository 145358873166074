import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { Banner } from "src/components/Banner";
import { Container } from "src/components/Container";
import { LinkList } from "src/components/LinkList";
import { PageWrapper } from "src/components/PageWrapper";
import { Typography } from "src/components/Typography";
import { PathEnum } from "src/enums/PathEnum";
import { useParams } from "src/hooks/useParams";
import { blue300 } from "src/styles/variables.module.scss";

import { VacancyList } from "./components/VacancyList";
import { getLinksByVacancyCategories, getVacanciesByCategory } from "./utils";
import * as styles from "./Vacancies.module.scss";

const query = graphql`
  query {
    allStrapiVacancyCategories {
      edges {
        node {
          id
          name
          vacancies {
            id
          }
        }
      }
    }
    allStrapiVacancies {
      edges {
        node {
          id
          name
          vacancy_categories {
            id
            name
          }
        }
      }
    }
  }
`;

export const Vacancies: React.FC = () => {
  const { category } = useParams();
  const data = useStaticQuery(query);

  return (
    <PageWrapper
      topAutoScroll
      title={`Вакансии${category ? `: ${category}` : ""}`}
    >
      <div>
        <Banner backgroundColor={blue300}>
          <Container>
            <Typography color="white" variant="h3">
              Вакансии
            </Typography>
          </Container>
          <Container>
            <LinkList
              startLink={
                <a href={PathEnum.Vacancies} className={styles.startLink}>
                  все
                </a>
              }
              items={getLinksByVacancyCategories(data, category)}
              linkProps={{
                color: "white",
                hoverFill: "pink",
                size: "s",
              }}
              flexWrap
            />
          </Container>
        </Banner>
        <VacancyList items={getVacanciesByCategory(data, category)} />
      </div>
    </PageWrapper>
  );
};
