import { LinkListItem } from "src/components/LinkList";
import { PathEnum } from "src/enums/PathEnum";
import { createLink } from "src/utils/createLink";

export const getVacanciesByCategory = (data, category?: string) => {
  let vacancies = data.allStrapiVacancies.edges;

  if (category) {
    vacancies = vacancies.filter(({ node: { vacancy_categories } }) =>
      vacancy_categories.some((item) => item.name === category)
    );
  }

  return vacancies.map(
    ({ node: { id, name, vacancy_categories, ...other } }) => ({
      id,
      name,
      vacancy_categories: vacancy_categories.map(({ name }) => ({
        text: name,
        href: createLink(PathEnum.Current, {
          category: name,
        }),
      })),
      ...other,
    })
  );
};

export const getLinksByVacancyCategories = (
  data,
  category?: string
): LinkListItem[] => {
  const links: LinkListItem[] = data.allStrapiVacancyCategories.edges
    .map((item) => {
      if (!item.node.vacancies.length) {
        return null;
      }

      return {
        text: item.node.name,
        href: createLink(PathEnum.Current, {
          category: item.node.name,
        }),
        active: category === item.node.name,
      };
    })
    .filter((item) => item !== null);

  return links;
};
