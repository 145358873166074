import React, { useState } from "react";
import { useEffect } from "react";

import { Button } from "src/components/Button";

import { ShortVacancy, VacancyItem } from "../VacancyItem";
import * as styles from "./VacancyList.module.scss";

interface Props {
  items: ShortVacancy[];
}

export const VacancyList: React.FC<Props> = ({ items }) => {
  const ITEMS_COUNT = 5;
  const [showedItems, setShowedItems] = useState([]);

  useEffect(() => {
    setShowedItems(items.splice(0, ITEMS_COUNT));
  }, []);

  const handleShowMoreClick = () => {
    setShowedItems(showedItems.concat(items.splice(0, ITEMS_COUNT)));
  };

  return (
    <div className={styles.root}>
      <div>
        {showedItems.map((item) => (
          <VacancyItem key={item.id} {...item} />
        ))}
      </div>
      {!!items.length && (
        <Button
          color="green"
          className={styles.button}
          onClick={handleShowMoreClick}
        >
          <a className={styles.buttonText}>показать еще</a>
        </Button>
      )}
    </div>
  );
};
